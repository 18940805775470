import { ifProp, theme } from 'styled-tools';
import styled, { css } from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Button from 'components/Button';

export const PageHeader = styled(Typography)`
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 3rem;

  ${(props) => props.theme.breakpoints.down('xs')} {
    font-family: ${theme('typography.fontFamilySecondary')};
    font-size: 0.8125rem;
    margin-bottom: 1.625rem;
    margin-top: 0;
    text-transform: uppercase;
  }
`;

export const SectionTitle = styled(Typography)`
  font-family: ${theme('typography.fontFamilySecondary')};
  font-size: 0.8125rem;
  font-weight: 600;
  margin-right: 1.25rem;
  text-transform: uppercase;

  ${(props) => props.theme.breakpoints.up('sm')} {
    font-size: 1.25rem;
  }
`;

export const SearchSection = styled.div`
  overflow-anchor: none;
  position: relative;

  ${ifProp(
    '$hasResults',
    css`
      &:not(:last-child) {
        margin-bottom: 5rem;
      }
    `
  )}
`;

export const SortContainer = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 1.625rem;

  ${(props) => props.theme.breakpoints.up('sm')} {
    margin-bottom: 1.5rem;
  }
`;

export const ResultsContainer = styled.div`
  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 82%,
    transparent 100%
  );

  ${(props) => props.theme.breakpoints.up('sm')} {
    margin-bottom: 4rem;
    mask-image: none;
  }
`;

export const PagingContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const MoreButton = styled(Button)`
  ${(props) => props.theme.breakpoints.up('sm')} {
    background-color: ${theme('colors.ttRed')};
    color: ${theme('colors.white')};
    font-family: ${theme('typography.fontFamilySecondary')};
    font-size: 0.875rem;
    margin-bottom: 1.5rem;
    padding: 0.75rem 2.75rem;
    text-transform: uppercase;

    &:hover {
      background-color: ${theme('colors.ttMuted')};
      color: ${theme('colors.white')};
    }
  }

  ${(props) => props.theme.breakpoints.down('xs')} {
    background-color: ${theme('colors.white')};
    border-radius: 50%;
    box-shadow: 0px 10px 20px #00000029;
    height: 3.125rem;
    margin-top: -2rem;
    min-width: auto;
    width: 3.125rem;

    &:hover {
      background-color: ${theme('colors.lightBorderGray')};
    }

    svg {
      font-size: 1.2em;
    }
  }
`;

export const FilterTab = styled.div`
  align-items: center;
  color: ${theme('colors.black')};
  display: flex;
  font-size: 1rem;
  font-weight: 500;
  justify-content: center;
  line-height: 1;
  text-transform: uppercase;
  position: relative;

  ${(props) => props.theme.breakpoints.up('sm')} {
    padding-bottom: 1.46875rem;
  }

  ${ifProp(
    '$filterOpen',
    css`
      color: ${theme('colors.ttRed')};
    `
  )}

  &:hover {
    cursor: pointer;
    color: ${theme('colors.ttRed')};
  }

  ${ifProp(
    '$disabled',
    css`
      opacity: 0.5;

      &:hover {
        color: ${theme('colors.black')};
        cursor: default;
      }
    `
  )}

  svg {
    height: 1rem;
    width: 1rem;
    margin: 0 1.125rem 0 0;
  }
`;
