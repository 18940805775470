import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import styled from 'styled-components';
import SEO from 'components/SEO';
import PageWrapper from 'components/PageWrapper';
import Tabs from 'components/Tabs';
import BaseControlBar from 'components/ControlBar';
import YearFilter from './components/YearFilter';
import ModularBlocks from 'components/ModularBlocks';
import useServerCacheHeaders from 'hooks/useServerCacheHeaders';
import httpCacheConfig, { addCacheTags } from 'config/httpCache';
import { SocialLinksContainer } from 'components/ModularBlocks/SocialLinks';
import { PressPostsContextProvider, usePressPostsContext } from 'context/PressPostsContext';
import { PageHeader as BasePageHeader } from 'routes/SearchPage/components/SharedStyles';

const PressPageWrapper = styled(PageWrapper)`
  ${SocialLinksContainer} {
    margin: 2rem 0 0 -1rem;
  }

  .press-page-sidebar-contacts, .press-page-sidebar-press-kit {
    h3 {
      font-size: 1.2rem;
    }
    
    h4 {
      margin-bottom: 0.3rem;
    }
    
    p {
      margin-top: 0;
      margin-bottom: 1.5rem;
    }
  }

  .press-page-sidebar-press-kit .MuiButton-label {
    svg {
      margin-bottom: 0.1rem;
    }
      
    &:hover {
      svg {
        transform: translate3d(0.3rem, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg);
        transition: transform 0.3s ease;
        transform-style: preserve-3d;
      }
    }
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.25rem;
  padding-top: 1.8125rem;

  ${props => props.theme.breakpoints.down('md')} {
    border: none;
    padding-bottom: 3rem;
    padding-top: 1rem;
  }

  ${props => props.theme.breakpoints.down('xs')} {
    border: none;
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
`;

const PageHeader = styled(BasePageHeader)`
  margin: 0;
  text-transform: uppercase;
  font-size: 1.5rem;
  line-height: 1.75rem;

  ${props => props.theme.breakpoints.down('xs')} {
    font-size: 0.8125rem;
  }
`;

const Controls = styled(BaseControlBar)`
  border-bottom: 0.0625rem solid #ccc;
`;

const tabsId = 'filtered-press-tabs';

const tabs = [
  { label: 'All' },
  { label: 'tastylive' },
  { label: 'tastytrade' },
  { label: 'tastycrypto' },
];

function FilteredPressPageComponent({ page }) {
  const { pageBody, sidebarBody } = page;
  const [selectedYear, setSelectedYear] = useState('All');
  const [activeTab, setActiveTab] = useState(0);
  const { handleChangeYear, handleChangeTab } = usePressPostsContext();

  useServerCacheHeaders(
    addCacheTags(httpCacheConfig.pressPage, [
      page?.uid,
      'header',
      'footer'
    ]),
  );

  const handleChangeActiveTab = (event, newValue) => {
    setActiveTab(newValue);
    setSelectedYear('All');
    handleChangeTab(tabs[newValue].label);
  };

  const handleChangeSelectedYear = (year) => {
    setSelectedYear(year);
    handleChangeYear(year);
  }

  return (<>
    <PressPageWrapper>
      <Container>
        <HeaderContainer>
          <PageHeader variant='h1'>Newsroom</PageHeader>
        </HeaderContainer>
        <Controls>
          <Tabs
            tabs={tabs}
            id={tabsId}
            value={activeTab}
            onChange={handleChangeActiveTab}
            controlBar={true}
            variant='scrollable'
          />
          <YearFilter handleChangeYear={handleChangeSelectedYear} selectedYear={selectedYear} years={pageBody[0].pressPostsList.yearsFilter}/>
        </Controls>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <ModularBlocks modularBlocks={pageBody} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ModularBlocks modularBlocks={sidebarBody}/>
          </Grid>
        </Grid>
      </Container>
    </PressPageWrapper>
  </>);
}

function FilteredPressPage({ page }) {
  const { seo, title } = page;

  return (
    <PressPostsContextProvider>
      <SEO seo={seo} title={title} />
      <FilteredPressPageComponent page={page} />
    </PressPostsContextProvider>
  )
}

export default React.memo(FilteredPressPage);
