import React from 'react'
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {theme} from 'styled-tools';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

const FilterContainer = styled.div`
  font-size: 0.875rem;
  padding-bottom: 0.6rem;
  font-family: ${theme('typography.fontFamilySecondary')};
  display: flex;
  align-items: center;
`;

const FilterLabel = styled(InputLabel)`
  text-transform: uppercase;
  padding-right: .625rem;
`;

const FilterSelect = styled(Select)`
  width: 8rem;
  border: 0.0625rem solid ${theme('colors.borderGray')};
  padding: .125rem;
  padding-left: .5rem;
  background-color: ${theme('colors.lightestGray')};

  .MuiSelect-select:focus {
    background-color: transparent;
  }

  .MuiSelect-icon {
    display: none;
  }

  &::before {
    content: none;
  }
    
  &::after {
    content: none;
  }
`;

const yearFilterLabel = 'year-filter-label'

YearFilter.propTypes = {
  handleChangeYear: PropTypes.func,
  selectedYear: PropTypes.number,
  years: PropTypes.arrayOf(PropTypes.string),
};

function YearFilter({ handleChangeYear, selectedYear, years }) {

  return (
    <FilterContainer>
      <FilterLabel id={yearFilterLabel}>Year</FilterLabel>
      <FilterSelect
        name='years'
        id='years-filter'
        label='Year'
        labelId={yearFilterLabel}
        value={selectedYear}
        onChange={(e) => handleChangeYear(e.target.value)}
        MenuProps={{
          PaperProps: {
            style: {
              width: '8rem', 
              transform: 'translate(-8px, 10px)'
            },
          },
        }}
      >
        {years.map((year) => (
          <MenuItem value={year} key={year}>
            {year}
          </MenuItem>
        ))}
      </FilterSelect>
    </FilterContainer>
  );
}

export default React.memo(YearFilter);