import React from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from './FacebookIcon';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

export const SocialLinksContainer = styled.div`
  margin: 2rem 0 0 0;
`;

const SocialLink = styled.a`
  font-size: 2rem;
  padding: 0 0.7rem;
  color: ${theme('colors.black')};

  &:hover {
    color: ${theme('colors.ttRed')};
    background-color: ${theme('colors.iconHoverBackgroundGray')};
    border-radius: 50%;
  }
`;

const SOCIAL_ICONS = {
  'Twitter': TwitterIcon,
  'Instagram': InstagramIcon,
  'Facebook': FacebookIcon,
  'LinkedIn': LinkedInIcon,
}

function SocialLinks({ data }) {
  const { social } = data

  return <SocialLinksContainer>
    {social && social.map((socialLink, idx) => {
        const ThisIcon = SOCIAL_ICONS[socialLink.name];
        return (
          <SocialLink key={idx} href={socialLink.url} target='_blank'>
            <ThisIcon/>
          </SocialLink>);
      }
    )}
  </SocialLinksContainer>
}

export default SocialLinks;