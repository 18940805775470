import React from 'react';
import PropTypes from 'prop-types';
import BaseTabs from '@material-ui/core/Tabs';
import BaseTab from '@material-ui/core/Tab';
import { ifProp, prop, theme } from 'styled-tools';
import styled, { css } from 'styled-components';

function a11yProps(id, index) {
  return {
    id: `${id}-tab-${index}`,
    'aria-controls': `${id}-tabpanel-${index}`,
  };
}

const StyledTabs = styled(BaseTabs)`
  ${ifProp(
    '$controlBar',
    css`
      margin-bottom: -1px;
    `
  )}

  ${ifProp(
    '$height',
    css`
      height: ${prop('$height')};
      min-height: ${prop('$height')};
    `
  )}

  .MuiTabs-indicator {
    background-color: ${prop('theme.colors.ttRed')};
  }
`;

export const Tab = styled(BaseTab)`
  font-size: 0.875rem;
  min-width: auto;
  padding: 0.75rem 1.875rem;
  font-family: ${theme('typography.fontFamilySecondary')};

  ${ifProp(
    '$height',
    css`
      height: ${prop('$height')};
      min-height: ${prop('$height')};
    `
  )}

  ${ifProp(
    '$disabled',
    css`
      opacity: 0.3;
    `
  )}

  &&& {
    &.Mui-disabled {
      cursor: default;
      opacity: 0.3;
    }
  }
`;

function Tabs({
  tabs,
  id,
  controlBar = false,
  disabled,
  height,
  scrollButtons = false,
  variant,
  ...props
}) {
  return (
    <StyledTabs
      id={`${id}-tabs`}
      $controlBar={controlBar}
      $height={height}
      scrollButtons={scrollButtons}
      variant={variant}
      {...props}
    >
      {tabs.map((tab, i) => (
        <Tab
          disabled={tab.disabled}
          key={i}
          label={tab.label}
          $height={height}
          tabindex={0}
          {...a11yProps(tab.id, i)}
        />
      ))}
    </StyledTabs>
  );
}

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.node).isRequired,
  id: PropTypes.string.isRequired,
  controlBar: PropTypes.bool,
  height: PropTypes.string,
  scrollButtons: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  variant: PropTypes.string,
};

export default Tabs;
